import styled from 'styled-components';
import React from 'react';

import Body02 from '../components/typography/Body02';
import Caption from '../components/typography/Caption';

const Footer = () => {
    return (
        <Root> 
            <Caption center >
                About 😊
            </Caption>

            <Body02 center>
                We are Anna and Petros Makrigiannis, the team behind reaction.menu.<br />
                With over 10 years of experience in creation of digital products and strong background in hospitality, <br />
                we've created an easy to use and intuitive digital menu solution. <br />
                Our mission is to make dining out a smoother, more enjoyable experience for everyone.
               </Body02>



        </Root>
    );
};

export default Footer;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 80px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
        margin-bottom: 120px;

    }
`;