export const demoUrl = 'https://app.reaction.menu/HG50kc1vSsYge7b9azBVyKs9TRV2';
export const signupUrl = 'https://app.reaction.menu/signup';
export const emailAddress = 'mailto: contact@reacton.menu';
export const loginUrl = 'https://app.reaction.menu/signin';
export const kostasUrl = 'https://app.reaction.menu/oSckj5l8bCXj5SFltVN0LIrA7Vf1';
export const donsUrl = 'https://app.reaction.menu/HzV9P4AfV2WeGiEhtdKentJJiYj1';
export const mostraUrl = 'https://app.reaction.menu/7QD2biAJciTz5Nbc4EsKHIsIsJl2';
export const aretiUrl = 'https://app.reaction.menu/6zEL6nnXDyeUCh2WIyHflfKvO192';
export const bistrotUrl = 'https://app.reaction.menu/UE03GbkAXuPKOP4u1raVLj8unNk1';
export const lokalUrl = 'https://app.reaction.menu/j5jKa07rQ6QNR6l2hemepsWHCZz2';

