import styled, { useTheme } from 'styled-components';
import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import '../styles/swiper.css';

import Body02 from '../components/typography/Body02';
import Body03 from '../components/typography/Body03';
import Caption from '../components/typography/Caption';
import Headline03 from '../components/typography/Headline03';
import { kostasUrl, donsUrl, mostraUrl, aretiUrl, bistrotUrl, lokalUrl } from '../constants';

import { ReactComponent as ArrowIcon } from '../img/arrow.svg';

import costasLogo from '../img/testim/logoCostas.svg';
import mostraLogo from '../img/testim/mostra_logo.png';
import donsLogo from '../img/testim/dons_logo.svg';
import aretiLogo from '../img/testim/areti_logo.png';
import bistrotLogo from '../img/testim/bistrot_logo.svg';
import lokalLogo from '../img/testim/lokal_logo.svg';

import img1 from '../img/testim/reaction_menu.png';
import img2 from '../img/testim/reaction_menu2.jpeg';
import img3 from '../img/testim/reaction_menu3.png';
import img5 from '../img/testim/tavern.JPEG';
import img6 from '../img/testim/1945.jpg';
import img7 from '../img/testim/lokal.jpg';

const imgs = [
    {
        image: img7,
        title: "ΛΟΚΑΛ",
        link: lokalUrl,
        logo: lokalLogo
    },
    {
        image: img6,
        title: "1943 Bistrot",
        link: bistrotUrl,
        logo: bistrotLogo
    },
    {
        image: img1,
        title: "Don's society cafe",
        link: donsUrl,
        logo: donsLogo
    },
    {
        image: img2,
        title: "Palia Kai Simera",
        link: kostasUrl,
        logo: costasLogo
    },
    {
        image: img3,
        title: "Mostra Meze Tavern",
        link: mostraUrl,
        logo: mostraLogo
    },
    {
        image: img5,
        title: "Areti Tavern",
        link: aretiUrl,
        logo: aretiLogo
    }
]

const Testimonials = () => {

    const theme = useTheme();

    return (
        <Root> 
            <Caption center>Our users 🤩</Caption>
            <StyledHeadline03 center>
                <Span>
                    Coffee shops and restaurants that already use
                </Span>
                <span>
                    reaction.menu to run their business 
                </span>
            </StyledHeadline03>

            <Swiper
                spaceBetween={ 16 }
                slidesPerView={"auto"}
                centeredSlides={ true }
                /*autoplay={{
                    "delay": 4500,
                    "disableOnInteraction": true
                  }}*/
                loop={ true }
                followFinger={ true }
                grabCursor={ true }

            >
                { imgs.map(slide => <StyledSwiperSlide>
                    <img src={ slide.image } alt="reaction.menu" />
                    <Content href={ slide.link } target="blank">
                        <Logo><img src={  slide.logo } alt="reaction.menu" /></Logo>
                        <Label>
                            <Body02 color={ theme.body } bold>
                                { slide.title }
                            </Body02>

                            <Link>
                            <Body03
                                color={ theme.invertTextSecondary }
                            >
                                View menu
                            </Body03>

                            <StyledArrowIcon />
                            </Link>
                        </Label>
                    </Content>
                </StyledSwiperSlide>)}      
            </Swiper>
        </Root>
    );
};

export default Testimonials;

const Root = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 80px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        margin-bottom: 120px;
    }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
    width: 80%;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 60%;
    };
`;

const Logo = styled.div`
    height: 40px; 

    img {height: 40px; width: auto;}
`;

const Label = styled.div`
`;

const Content = styled.a`
    align-items: center;
    background: ${ ({ theme }) => theme.text };
    border-radius: 32px;
    display: flex;
    gap: 16px;
    left: 4px;
    padding: 4px 12px;
    position: absolute;
    top: inherit;
    z-index: 100;
    bottom: 8px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        left: 16px;
        padding: 8px 24px 8px 8px;
        top: 16px;
        bottom: inherit;
    };
`;

const StyledHeadline03 = styled(Headline03)`
    margin-bottom: 16px;
`;

const Span = styled.span`
    display: inline;
    margin-right: 7px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        display: block;
    };
`;

const Link = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 2px;
`;

const StyledArrowIcon = styled(ArrowIcon)`
    width: 40px;

    path { fill: ${ ({ theme }) => theme.invertTextSecondary } }
`;


