
import React from 'react';
import styled from 'styled-components';

import Body01 from '../components/typography/Body01';
import Headline01 from '../components/typography/Headline01';
import Headline03 from '../components/typography/Headline03';
import Buttons from '../components/Buttons';
import video from '../img/teaser.mp4'
import poster from '../img/teaser.jpg'

const FirstPage = () => {
    return (
        <Root>
            <FlexWrapper>
                <Content>
                    <Headline01>
                        Interactive<br />
                       QR menu &<br />
                       <span1>link-in-bio</span1>
                    </Headline01>

                    <Body01>
                        Gather your digital menu, socials, location, reviews, wifi password, delivery & reservation links in one place                     
                    </Body01>

                    <Buttons />
                </Content>

                <VideoContent>
                    <Video src={ video } loop poster={ poster } 
                        autoPlay muted playsInline
                    
                    />
                </VideoContent>
            </FlexWrapper>

            <StyledHeadline03>
                Centralize online presence of your restaurant, bar, café, or barbershop <span2>today</span2>. Go live in minutes
            </StyledHeadline03>
        </Root>
    )}

export default FirstPage;

const Root = styled.div`
    width: 100%;
    padding: 0 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
    }
`;
  
const FlexWrapper = styled.div`
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 0px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row; 
        height: 88vh;
        gap: 80px;
    }
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 96px;
    gap: 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 50%;
        margin-top: 0;
        gap: 32px;
    }
`;

const VideoContent = styled.div`
    position: relative;
    width: 100%;
    
    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 50%;
        padding-top: 80px;
    }
`;

const Video = styled.video`
    border-radius: 16px;
    position: relative;
    width: 100%;
    object-fit: cover;
    height: 400px;
    object-position: 50% 50%;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        :after {
            content: "";
            display: block;
        }
        height: 100%;
        object-fit: cover;
    }
`;

const StyledHeadline03 = styled(Headline03)`
   margin-top: 24px;

   @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        margin-top: 40px;
    }
`;